import { Component } from "react";
import ThankYouCheck from '../assets/img/check-thank-you-message.png'


class SurveySubmitted extends Component {
  constructor(props) {
    super(props);
    this.state = {
      convertedMessage: '',
    };
  }

  componentDidMount() {
    const { location } = this.props.history;
    if (location.state && location.state.message) {
      const message = location.state.message;
      this.convertHTMLToText(message);
    }
  }

  convertHTMLToText(html) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const text = this.parseNode(doc.documentElement);
    this.setState({ convertedMessage: text });
  }

  parseNode(node) {
    let result = '';

    for (let i = 0; i < node.childNodes.length; i++) {
      const child = node.childNodes[i];

      if (child.nodeType === Node.TEXT_NODE) {
        result += child.textContent;
      } else if (child.nodeType === Node.ELEMENT_NODE) {
        if (child.nodeName === 'STRONG' || child.nodeName === 'B') {
          result += '<strong>' + this.parseNode(child) + '</strong>';
        } else if (child.nodeName === 'EM' || child.nodeName === 'I') {
          result += '<em>' + this.parseNode(child) + '</em>';
        } else if (child.nodeName === 'SPAN' && child.style.color) {
          const color = child.style.color;
          result += '<span style="color:' + color + '">' + this.parseNode(child) + '</span>';
        } else {
          result += this.parseNode(child);
        }
      }
    }

    return result;
  }

  render() {
    const { location } = this.props.history;
    console.log(location.state.message);
    if (location.state && location.state.message) {
      const { convertedMessage } = this.state;
      const message = location.state.message;

  
      // Check if the message contains the image tag
      if (message.includes('check-thank-you-message.png')) {
        return (
          <>
            <div className="content container-fluid">
              <div className="row">
                <div className="survey-backround2 survey-expaired-wrapper">
                  <div className='session-exp-text' dangerouslySetInnerHTML={{ __html: convertedMessage }}></div>
                  <img src={ThankYouCheck} alt="Thank You"  style={{borderRadius: "50%"}}/>
                </div>
              </div>
            </div>
          </>
        );
      } else {
        return (
          <>
            <div className="content container-fluid">
              <div className="row">
                <div className="survey-backround2 survey-expaired-wrapper">
                  <div className='session-exp-text' dangerouslySetInnerHTML={{ __html: convertedMessage }}></div>
                </div>
              </div>
            </div>
          </>
        );
      }
    }
  
    return null;
  }  
}

export default SurveySubmitted;
