import { Component } from "react";

class SurveyExpired extends Component {
    constructor(props) {
        super(props);
    }
   
    render() { 
        return (
        <>
            <div className="content container-fluid">
                <div className="row">
                <div className="survey-backround2 survey-expaired-wrapper">
                    <i class="fa fa-exclamation-triangle survey-expaired-icon" aria-hidden="true"></i>
                    <h2 className="session-exp-title">Session Expired!</h2>
                    <p  className="session-exp-text">Your survey session has expired!</p>
                </div>
                </div>
            </div>
        </>  );
    }
}
 
export default SurveyExpired;