import { Component } from "react";

class InvalidUrl extends Component {
    constructor(props) {
        super(props);
        this.state = {  }
    }
    render() { 
        return ( <>
            <div className="content container-fluid">
                <div className="row">
                <div className="survey-backround2 survey-expaired-wrapper">
                    <h1 className="not-fount-no">404</h1>
                    <h2 className="invalid-exp-title">Not Found</h2>
                    <p className="session-exp-text">Invalid web redirect URL.</p>
                </div>
                </div>
            </div>
            </> );
    }
}
 
export default InvalidUrl;